jQuery.timeago.settings.strings["en"] = {
  prefixAgo: null,
  prefixFromNow: null,
  suffixAgo: "",
  suffixFromNow: "",
  seconds: "<1m",
  minute: "1m",
  minutes: "%dm",
  hour: "1h",
  hours: "%dh",
  day: "1d",
  days: "%dd",
  month: "1mo",
  months: "%dmo",
  year: "1yr",
  years: "%dyr",
  wordSeparator: " ",
  numbers: []
};
