//$( document ).ready(function() {
$(document).on('turbolinks:load', function() {

// Handle message dismiss
$('.message .close')
  .on('click', function() {
    $(this)
      .closest('.message')
      .transition('fade')
    ;
  })
;

/* If it's a message right after body, it's the FRails Flash Messages */
/* via: http://stackoverflow.com/questions/32488021/semantic-ui-how-to-autoclose-modal */

// Handle alerts
$('.ui.message.flash')
  //.modal('show')
  .delay(4000)
  .queue(function() {
      $(this).transition('fade out').dequeue();
  })
;

});
