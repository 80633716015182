//$(document).on('turbolinks:load', function() {
$( document ).ready(function() {

  $('#send_message_form')
  .form({
    on: 'blur',
    fields: {
      from     : 'empty',
      email     : 'email',
      message     : 'empty',
      date_captcha_answer : 'empty'
    }
  })
;

});
