// SEMANTIC VALIDATIONS

//$(document).on('turbolinks:load', function() {
$( document ).ready(function() {


// contact_info
// ... name required
// ... email required and must be email
// ... url optional and must be url

$('.ui.form.contactinfo_edit')
  .form({
    fields: {
      name: {
        identifier: 'piece[datablob][name]',
        rules: [{
          type: "empty",
          prompt: "You must supply a name"
        }]
      },
      url: {
        identifier: 'piece[datablob][url]',
        optional   : true,
        rules: [{
          type: "url",
          prompt: "You must supply a valid url"
        }]
      },
      email: {
        identifier: 'piece[datablob][email]',
        rules: [{
          type: "email",
          prompt: "Please select a valid email"
        }]
      }
    }
  })
;

// overview, profgoals
// ... body required

$('.ui.form.overview_edit,.ui.form.profgoal_edit')
  .form({
    fields: {
      'piece[datablob][body]'     : 'empty'
    }
  })
;

// other
// ... name required

$('.ui.form.other_edit,.ui.form.skill_edit')
  .form({
    fields: {
      'piece[datablob][name]'    : 'empty'
    }
  })
;

// award, publication, certif
// ... name required
// ... started-at must be a date

$('.ui.form.award_edit,.ui.form.publication_edit,.ui.form.certif_edit')
  .form({
    fields: {
      name: {
        identifier: 'piece[datablob][name]',
        rules: [{
          type: "empty",
          prompt: "You must supply a name"
        }]
      },
      started_at: {
        identifier: 'piece[started_at]',
        rules: [{
          //type: "regExp[/^(0[1-9]|1[012])[- /.](0[1-9]|[12][0-9]|3[01])[- /.](19|20)\\d\\d$/]",
          type: "regExp[/^(19|20)\\d\\d[- /.](0[1-9]|[12][0-9]|3[01])[- /.](0[1-9]|1[012])$/]",
          //type: "regExp[/^(0[1-9]|1[012])[- /.](0[1-9]|[12][0-9]|3[01])[- /.](19|20)\\d\\d$/]",
          //type: "regExp(/^(19|20)\\d\\d$/][- /.](0[1-9]|[12][0-9]|3[01])[- /.][(0[1-9]|1[012])",
          prompt: "Please select a valid mm/dd/yyyy date"
        }]
      }
    }
  })
;

// membership
// ... requires name
// ... started-at must be a date
// ... ended-at must be a date

$('.ui.form.membership_edit')
  .form({
    fields: {
      name: {
        identifier: 'piece[datablob][name]',
        rules: [{
          type: "empty",
          prompt: "You must supply a name"
        }]
      },
      started_at: {
        identifier: 'piece[started_at]',
        rules: [{
          //type: "regExp[/^(0[1-9]|1[012])[- /.](0[1-9]|[12][0-9]|3[01])[- /.](19|20)\\d\\d$/]",
          type: "regExp[/^(19|20)\\d\\d[- /.](0[1-9]|[12][0-9]|3[01])[- /.](0[1-9]|1[012])$/]",
          prompt: "Please select a valid mm/dd/yyyy date"
        }]
      },
      ended_at: {
        identifier: 'piece[ended_at]',
        rules: [{
          //type: "regExp[/^(0[1-9]|1[012])[- /.](0[1-9]|[12][0-9]|3[01])[- /.](19|20)\\d\\d$/]",
          type: "regExp[/^(19|20)\\d\\d[- /.](0[1-9]|[12][0-9]|3[01])[- /.](0[1-9]|1[012])$/]",
          prompt: "Please select a valid mm/dd/yyyy date"
        }]
      }
    }
  })
;

// education
// ... school required
// ... degree required
// ... started-at must be a date

$('.ui.form.education_edit')
  .form({
    fields: {
      school: {
        identifier: 'piece[datablob][school]',
        rules: [{
          type: "empty",
          prompt: "You must supply a school name"
        }]
      },
      degree: {
        identifier: 'piece[datablob][degree]',
        rules: [{
          type: "empty",
          prompt: "You must supply a degree"
        }]
      },
      started_at: {
        identifier: 'piece[started_at]',
        rules: [{
          //type: "regExp[/^(0[1-9]|1[012])[- /.](0[1-9]|[12][0-9]|3[01])[- /.](19|20)\\d\\d$/]",
          type: "regExp[/^(19|20)\\d\\d[- /.](0[1-9]|[12][0-9]|3[01])[- /.](0[1-9]|1[012])$/]",
          prompt: "Please select a valid mm/dd/yyyy date"
        }]
      }
    }
  })
;

// experience
// ... experience required
// ... company required
// ... location required
// ... started-at must be a date

$('.ui.form.experience_edit')
  .form({
    fields: {
      position: {
        identifier: 'piece[datablob][position]',
        rules: [{
          type: "empty",
          prompt: "You must supply a position name"
        }]
      },
      company: {
        identifier: 'piece[datablob][company]',
        rules: [{
          type: "empty",
          prompt: "You must supply a company name"
        }]
      },
      location: {
        identifier: 'piece[datablob][location]',
        rules: [{
          type: "empty",
          prompt: "You must supply a location"
        }]
      },
      started_at: {
        identifier: 'piece[started_at]',
        rules: [{
          //type: "regExp[/^(0[1-9]|1[012])[- /.](0[1-9]|[12][0-9]|3[01])[- /.](19|20)\\d\\d$/]",
          type: "regExp[/^(19|20)\\d\\d[- /.](0[1-9]|[12][0-9]|3[01])[- /.](0[1-9]|1[012])$/]",
          prompt: "Please select a valid mm/dd/yyyy date"
        }]
      }
    }
  })
;

// project
// ... experience required
// ... company required
// ... location required
// ... started-at must be a date

$('.ui.form.project_edit')
  .form({
    fields: {
      name: {
        identifier: 'piece[datablob][name]',
        rules: [{
          type: "empty",
          prompt: "You must supply a name"
        }]
      },
      company: {
        identifier: 'piece[datablob][company]',
        rules: [{
          type: "empty",
          prompt: "You must supply a company name"
        }]
      },
      location: {
        identifier: 'piece[datablob][location]',
        rules: [{
          type: "empty",
          prompt: "You must supply a location"
        }]
      },
      started_at: {
        identifier: 'piece[started_at]',
        rules: [{
          //type: "regExp[/^(0[1-9]|1[012])[- /.](0[1-9]|[12][0-9]|3[01])[- /.](19|20)\\d\\d$/]",
          type: "regExp[/^(19|20)\\d\\d[- /.](0[1-9]|[12][0-9]|3[01])[- /.](0[1-9]|1[012])$/]",
          prompt: "Please select a valid mm/dd/yyyy date"
        }]
      }
    }
  })
;


});

// SELECTIZE

$( document ).ready(function() {

  $('.piece-tags').selectize({
      plugins: ['remove_button'],
      delimiter: ',',
      loadThrottle: 60,
      persist: false,
      valueField: 'name',
      labelField: 'name',
      searchField: ['name'],
      preload: true,
      create: true,
    //createOnBlur: true,
//    allowEmptyOption: true,
      //create: function(input) {
      //    return {
      //        value: input,
      //        text: input
      //    }
      //}

    onChange: function(value) {
      console.log(value);
      //console.log(JSON.stringify(event));
      //console.log(JSON.stringify(event.target));
      console.log($(':focus'));
      console.log($(':focus')[0].id);
      //var piece_id = event.target.id.split("-")[2];
      var piece_id = $(':focus')[0].id.split("-")[2];
      console.log(piece_id);
      //alert("chnage");
      //alert(value);
      $.ajax({
          url: "/tags/" + piece_id + "/tag_piece",
          type: 'POST',
          dataType: 'json',
          data: {
              tags: value //,
              //additionalDataIfRequired: 'Additional Data'
          },
          error: function() {
              //callback();
          },
          success: function(res) {
              // you can apply any modification to data before passing it to selectize
              //callback(res);

              // res is json response from server
              // it contains array of objects. Each object has two properties. In this case 'id' and 'Name'
              // if array is inside some other property of res like 'response' or something. than use this
              //callback(res.response);
          }
      });
    },

    load: function(query, callback) {
        if (!query.length) return callback();
        $.ajax({
            url: "/tags/index",
            type: 'GET',
            dataType: 'json',
            data: {
              name: query //,
              //additionalDataIfRequired: 'Additional Data'
            },
            error: function() {
              callback();
            },
            success: function(res) {
              // you can apply any modification to data before passing it to selectize
              callback(res);

              // res is json response from server
              // it contains array of objects. Each object has two properties. In this case 'id' and 'Name'
              // if array is inside some other property of res like 'response' or something. than use this
              //callback(res.response);
            }
        });
      }
  });

});

